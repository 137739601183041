import { RouteConfig } from 'vue-router';
import generateRoute from '@/router/generateRoute';
import PageNotFound from '@/views/PageNotFound/PageNotFound.vue';
import LayoutBlank from '@/layouts/LayoutBlank.vue';
import { handleLoginCallback, redirectToLogin } from '@/router/guardHelper';

const SetPassword = () =>
  import(
    /* webpackChunkName: 'set-pw' */
    '@/views/SetPassword/SetPassword.vue'
  );
const AccountSettings = () =>
  import(
    /* webpackChunkName: 'account-settings' */
    '@/views/AccountSettings/AccountSettings.vue'
  );
const SignOut = () =>
  import(
    /* webpackChunkName: 'signout' */
    '@/views/SignOut/SignOut.vue'
  );
const VerifyEmail = () =>
  import(
    /* webpackChunkName: 'verifyemail' */
    '@/views/VerifyEmail/VerifyEmail.vue'
  );

// Views that are not in the view directory
const PasswordChanged = () =>
  import(
    /* webpackChunkName: 'pw-changed' */
    '@/components/PasswordChanged/PasswordChanged.vue'
  );
const AccountDeleted = () =>
  import(
    /* webpackChunkName: 'account-deleted' */
    '@/components/AccountDeleted/AccountDeleted.vue'
  );
const AccountOverviewLister = () =>
  import(
    /* webpackChunkName: 'private-signup' */
    '@/views/AccountOverviewLister/AccountOverviewLister.vue'
  );
const AccountOverviewSeeker = () =>
  import(
    /* webpackChunkName: 'private-signup' */
    '@/views/AccountOverviewSeeker/AccountOverviewSeeker.vue'
  );
const Overview = () =>
  import(
    /* webpackChunkName: 'overview' */
    '@/views/Welcome/Welcome.vue'
  );

const routes: RouteConfig[] = [
  generateRoute({
    path: '/:locale/sign-in',
    name: 'signIn',
    component: LayoutBlank,
    beforeEnter: redirectToLogin,
  }),
  generateRoute({
    path: '/:locale/sign-out',
    name: 'signOut',
    component: SignOut,
    meta: {
      isPrivate: true,
    },
  }),
  generateRoute({
    path: '/:locale/verify-email',
    name: 'verifyEmail',
    component: VerifyEmail,
    meta: {
      isPrivate: true,
    },
  }),
  generateRoute({
    path: '/:locale/set-password',
    name: 'setPassword',
    component: SetPassword,
  }),
  generateRoute({
    path: '/:locale/password-changed',
    name: 'passwordChanged',
    component: PasswordChanged,
  }),
  generateRoute({
    path: '/:locale/account-settings',
    name: 'accountSettings',
    component: AccountSettings,
    meta: {
      authUser: true,
      isPrivate: true,
      isMenuNavigationShow: true,
    },
  }),
  generateRoute({
    path: '/:locale/account-deleted',
    name: 'accountDeleted',
    component: AccountDeleted,
  }),
  generateRoute({
    path: '/:locale/overview',
    name: 'overview',
    component: Overview,
    meta: {
      authUser: true,
      isPrivate: true,
      isMenuNavigationShow: true,
    },
  }),
  generateRoute({
    path: '/:locale/overview/lister',
    name: 'accountOverviewLister',
    component: AccountOverviewLister,
    meta: {
      authUser: true,
      isPrivate: true,
      isMenuNavigationShow: true,
    },
  }),
  generateRoute({
    path: '/:locale/overview/seeker',
    name: 'accountOverviewSeeker',
    component: AccountOverviewSeeker,
    meta: {
      authUser: true,
      isPrivate: true,
      isMenuNavigationShow: true,
    },
  }),
  generateRoute({
    path: '/:locale/*',
    name: 'pageNotFound',
    component: PageNotFound,
  }),
  {
    path: '/login-callback',
    component: LayoutBlank,
    beforeEnter: handleLoginCallback,
  },
  {
    path: '*',
    redirect: '/:locale/sign-in',
  },
  {
    path: '/:locale',
    redirect: '/:locale/sign-in',
  },
];

export default routes;
