import axios from 'axios';
import * as Sentry from '@sentry/vue';
import { AuthenticationModel, SubscriptionModel, SubscriptionState } from './types';
import { isAfter } from 'date-fns/isAfter';
import { isSubscriptionModel } from './typeGuards';
import { getAccessToken, getUserId } from '@/helpers/authTokenHelper';

import { isBefore } from 'date-fns/isBefore';
import { addDays } from 'date-fns/addDays';

export async function authenticateTenantPlusUser(): Promise<AuthenticationModel> {
  const defaultAuthenticationModel = { isTenantPlusSubscriber: false, subscriptionInfo: undefined };

  try {
    const accessToken = await getAccessToken();

    if (!accessToken) {
      return defaultAuthenticationModel;
    }

    const { data } = await axios.get(`${build.config.TENANT_PLUS_API_URL}/authentication`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      withCredentials: true,
    });

    return { isTenantPlusSubscriber: true, subscriptionInfo: data };
  } catch (error) {
    if (error.response?.status === 401 && isSubscriptionModel(error.response?.data)) {
      return { isTenantPlusSubscriber: false, subscriptionInfo: error.response?.data as SubscriptionModel };
    }

    if (error.response?.status !== 401 && error.response?.status !== 403) {
      Sentry.captureException(`Failed to authenticate TenantPlus user. Unexpected error occurred. Error: ${error}`);
    }

    return defaultAuthenticationModel;
  }
}

export async function cancelSubscription(): Promise<boolean> {
  try {
    const accessToken = await getAccessToken();
    if (!accessToken) {
      return false;
    }
    const userId = await getUserId();
    if (accessToken && !userId) {
      return false;
    }
    await axios.put(
      `${build.config.TENANT_PLUS_API_URL}/cancellations/${userId}`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return true;
  } catch (error) {
    Sentry.captureException(error);
    return false;
  }
}

export async function cancelRenewal(): Promise<boolean> {
  try {
    const accessToken = await getAccessToken();
    if (!accessToken) {
      return false;
    }
    const userId = await getUserId();
    if (accessToken && !userId) {
      return false;
    }
    await axios.put(
      `${build.config.TENANT_PLUS_API_URL}/subscriptions/${userId}/status`,
      { autoRenewalEnabled: false },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );

    return true;
  } catch (error) {
    Sentry.captureException(error);
    return false;
  }
}

export function isActiveSubscription(subscription: SubscriptionModel | undefined): boolean {
  if (!subscription) {
    return false;
  }

  if (!subscription.expiredAt) {
    return true;
  }

  return isAfter(new Date(subscription.expiredAt), Date.now());
}

export const isTrialSubscription = (isTenantPlusSubscriber: boolean, subscriptionInfo: SubscriptionModel): boolean => {
  const { metadata, subscriptionState, trialExpiredAt } = subscriptionInfo || {};
  return (
    isTenantPlusSubscriber &&
    !!metadata?.hadTrial &&
    subscriptionState === SubscriptionState.CREATED &&
    isBefore(Date.now(), addDays(new Date(trialExpiredAt), 1))
  );
};
