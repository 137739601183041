import once from 'lodash.once';

export enum GTMPageType {
  AUTH = 'account_auth',
  HUB = 'account_hub',
  ACCOUNT = 'user_account',
}

export enum GTMPageSubType {
  ACCOUNT_DELETE = 'account_delete',
  RESET_PW_INITIATE = 'reset_pw_initiate',
  RESET_PW_CHECK_INBOX = 'reset_pw_checkInbox',
  RESET_PW_CREATE_NEW_PW = 'reset_pw_createNewPw',
  SET_PW = 'set_pw',
  SETTINGS = 'account_settings',
  SIGN_UP = 'sign_up',
  LOGIN = 'login',
  LOGOUT = 'logout',
  OVERVIEW = 'overview',
  VERIFY_EMAIL_INIT = 'email_verification',
  EMAIL_CONFIRMATION = 'email_confirmation',
}

export enum GTMPageFeatureId {
  INITIAL_PAGE_LOAD = 'p014',
  AUTHENTICATION_EVENT = 'e042',
  CLICK_EVENT = 'e045',
  PARTNER_SERVICE = 'e022',
}

export enum GTMEvent {
  LOAD = 'load',
  AUTHENTICATION = 'authentication',
  ACCOUNT_INTERACTION = 'account_interaction',
  PARTNER_LINK_CLICK = 'partner_link_click',
  FOOTER_CLICK = 'footer_interaction',
  HEADER_CLICK = 'header_interaction',
  PAGE_VIEW = 'virtual_pageview',
}

export enum InteractionContainerName {
  SIDE_NAVIGATION = 'sideNavigation',
  ACCOUNT_OVERVIEW = 'user-account',
}

export enum InteractionElement {
  LINK = 'link',
  CTA_BUTTON = 'ctaButton',
  SECONDARY_BUTTON = 'secondaryButton',
  TEXT_LINK = 'textlink',
}

export enum InteractionContainerDetail {
  SUCCESSFUL = 'successful',
  FAILED = 'failed',
  PRODUCT = 'product',
  GRAPH = 'graph',
  QUALITY_CARD = 'qualityCard',
  RANKING_CARD = 'rankingCard',
  LINK = 'link',
  SECONDARY_BUTTON = 'secondaryButton',
  IN_WIDGET = 'inWidget',
  SEEKER_TO_LISTER = 'fromSeeker_toLister',
  LISTER_TO_SEEKER = 'fromLister_toSeeker',
  TENANT = 'tenant',
  PROPERTY = 'property',
  TENANT_PLUS = 'tenantPlus',
  TENANT_PLUS_TRIAL = 'tenantPlus_trial',
  TENANT_PLUS_RE_SIGNUP = 'tenantPlus_reSignUp',
  TENANT_PLUS_RENEWAL = 'tenantPlus_autoRenewal',
  TENANT_PLUS_HOW_IT_WORKS = 'tenantPlus_howItWorks',
  TENANT_PLUS_WELCOME_MODAL = 'tenantPlus_welcome_modal',
  TENANT_PLUS_ACCOUNT_OVERVIEW = 'tenantPlus_account_overview',
}

export enum InteractionGoal {
  LOGIN = 'auth_login',
  SIGN_UP = 'auth_sign_up',
  LOGOUT = 'auth_logout',
  GO_TO_MYHOMEGATE = 'goTo_myHomegate',
  GO_TO_PPR = 'goTo_propertyPerformanceReport',
  GO_TO_MR = 'goTo_marketingReport',
  GO_TO_DASHBOARD = 'goTo_dashboard',
  GO_TO_LISTINGS = 'goTo_listings',
  GO_TO_NEW_LISTING = 'goTo_newListing',
  GO_TO_HOMEGATE = 'goTo_homegate',
  GO_TO_LOGOUT = 'goTo_logout',
  CONTACT_US = 'contact_us',
  FORGOT_PASSWORD = 'forgot_password',
  CREATE_NEW_ACCOUNT = 'create_newAccount',
  CHANGE_EMAIL = 'change_email',
  CHANGE_PASSWORD = 'change_password',
  DELETE_ACCOUNT = 'delete_account',
  CHANGE_EMAIL_SUCCESS = 'change_email_success',
  CHANGE_PASSWORD_SUCCESS = 'change_password_success',
  DELETE_ACCOUNT_SUCCESS = 'delete_account_success',
  VERIFY_EMAIL = 'verify_email',
  GO_TO_SUBMIT_AD = 'goTo_submitAd',
  GO_TO_MY_ADS = 'goTo_myAds',
  GO_TO_MESSENGER = 'goTo_messenger',
  GO_TO_FAVORITES = 'goTo_favourites',
  GO_TO_SEARCH_ALERTS = 'goTo_searchAlerts',
  GO_TO_SERVICE_PARTNER_MOVU = 'movingCleaningQuotes',
  GO_TO_SERVICE_PARTNER_DEBT_COLLECTION = 'orderDebtCollectionRegister',
  GO_TO_SERVICE_PARTNER_TAXES = 'locationDemographyTaxesOverview',
  GO_TO_SETTINGS = 'goTo_settings',
  GO_TO_USER_PROFILE = 'go_to_profile"',
  SELECT_ACCOUNT_VIEW = 'select_accountView',
  GO_TO_ACCOUNT_VIEW = 'iAmLookingFor',
  GO_TO_TENANT_PLUS = 'learnMore',
  START_NEW_SEARCH = 'startNewSearch',
  SEE_EXCLUSIVE_LISTINGS = 'seeExclusiveListings',
  HOW_IT_WORKS = 'howItWorks',
  TENANT_PLUS_SIGNUP = 'signUpTenantPlus',
  TENANT_PLUS_CANCEL_SUBSCRIPTION = 'cancelSubscription',
  TENANT_PLUS_CONFIRM_CANCEL_SUBSCRIPTION = 'confirm_cancelSubscription',
  TENANT_PLUS_GET_COLLECTION_REPORT = 'tplus_get_collections_report',
  OPEN_INSERT_AD = 'open_insertAd',
  OPEN_FAVOURITES = 'open_favourites',
  OPEN_LOGIN = 'open_login',
  OPEN_ACCOUNT_OVERVIEW = 'open_accountOverview',
  OPEN_ACCOUNT_SETTINGS = 'open_accountSettings',
  OPEN_MY_ADS = 'open_myAds',
  OPEN_MESSENGER = 'open_messenger',
  OPEN_LOGOUT = 'logout',
  OPEN_SEARCH_ALERT = 'open_searchAlert',
}

export enum ClientLoginStatus {
  LOGGED_IN = 'loggedin',
  LOGGED_OUT = 'loggedout',
}

export enum ClientLoginProvider {
  HOMEGATE = 'homegate',
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  APPLE = 'apple',
}

export enum EventLabel {
  TAB_NAVIGATION = 'tab_navigation',
  SIDEBAR = 'sidebar',
}

export enum EventCategory {
  FOOTER_CLICK = 'footer_interaction',
  HEADER_CLICK = 'header_interaction',
}

export type TrackingParameterValue =
  | string
  | number
  | undefined
  | boolean
  | GTMEvent
  | InteractionContainerDetail
  | InteractionGoal
  | GTMEventCallback;

export interface GTMPageViewTrackingParameters {
  tech_featureId: GTMPageFeatureId;
  page_type: GTMPageType;
  page_subType?: GTMPageSubType;
  page_contentLanguage: string;
  page_realUrl?: string;
  page_normalizedUrl?: string;
  [additionalParameters: string]: any;
}
export type HarmonizedTrackingKeys =
  | 'h_page_type_110'
  | 'h_page_subtype_112'
  | 'h_content_language_116'
  | 'h_login_status_035'
  | 'h_client_login_user_type_037'
  | 'h_client_login_provider_034'
  | 'h_client_login_user_id_036'
  | 'h_is_tenant_plus_user_166'
  | 'h_page_teaser_name_displayed_114'
  | 'h_event_category_006'
  | 'h_event_action_007'
  | 'h_event_label_008'
  | 'h_newsletter_activated_043'
  | 'h_client_agency_id_033'
  | 'h_target_page_title_026'
  | 'h_target_url_029';

export type HarmonizedTracking = Partial<Record<HarmonizedTrackingKeys, TrackingParameterValue>>;

export function isGTMEnabled(): boolean {
  return 'dataLayer' in window;
}

export type GTMEventCallback = () => void;

export function trackEvent(eventParams: Record<string, TrackingParameterValue> = {}): void {
  // callback handling - needs to be fired even if tracking blockers are active
  if (!!eventParams.eventCallback) {
    // make sure eventCallback is only called once, and never again by any fallback timeout
    const eventCallbackOnce = once(eventParams.eventCallback);

    // this happens when Google Tag Manager is not loaded (e.g. in development without SSR or blocked)
    if (!isGTMEnabled()) {
      eventCallbackOnce();
      return;
    }

    // Extend the event params
    eventParams.eventCallback = eventCallbackOnce;
    eventParams.eventTimeout = (eventParams?.eventTimeout ?? 1000) as number;

    // if eventCallback is not executed within timeout the callback is executed manually
    window.setTimeout(eventCallbackOnce, eventParams.eventTimeout + 50);
  }

  if (isGTMEnabled()) {
    window.dataLayer.push(eventParams);
  }
}

export function trackEventHarmonized(eventName: string, eventParams: HarmonizedTracking = {}): void {
  if (!isGTMEnabled()) {
    return;
  }

  window.dataLayer.push({
    ...eventParams,
    event: eventName,
  });
}
