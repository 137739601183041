import { NavigationGuardNext, Route } from 'vue-router';
import { getAuth0Vue, VueAuth } from '@/auth/auth0Plugin';
import { GroupType, User } from '@/auth/types';
import { isPlatformIs24 } from '@/helpers/PlatformDetection';
import { StorageHelper } from '@smg-real-estate/hg-search-libs';
import { Key, UserType } from '@/helpers/Storage';
import { authenticateTenantPlusUser } from '@/helpers/TenantPlusService';
import {
  getHGOverviewListerUrlHref,
  getHGOverviewSeekerUrlHref,
  getIS24OverviewListerUrlHref,
  getIS24OverviewSeekerUrlHref,
} from '@/router/utils';

const redirectToLogin = async (targetRoute: Route, _sourceRoute: Route, next: NavigationGuardNext) => {
  const auth0 = await getAuth0Vue();
  auth0.loginWithRedirect({
    appState: { locale: targetRoute.params.locale },
    authorizationParams: {
      ui_locales: targetRoute.params.locale,
    },
  });
  next(false);
};

const redirectToUserLandingPage = (user: User, next: NavigationGuardNext, locale: string) => {
  if (user?.groups.includes(GroupType.AGENCY)) {
    if (isPlatformIs24()) {
      const baseUrl = build.config.IS24_BASE_URL;
      window.location.assign(`${baseUrl}/${locale}/smg-business-manager`);
    } else {
      const baseUrl = build.config.HOMEGATE_BASE_URL;
      window.location.assign(`${baseUrl}/${locale}/business/`);
    }
    next(false);
  } else {
    next({
      path: isPlatformIs24() ? `/${locale}/account-overview` : `/${locale}/overview`,
      replace: true,
    });
  }
};

const handleLoginCallback = async (_targetRoute: Route, _sourceRoute: Route, next: NavigationGuardNext) => {
  const auth0 = await getAuth0Vue();

  try {
    const { targetPath, locale } = (await auth0.handleRedirectCallback()) ?? {};

    if (targetPath) {
      return next({
        path: targetPath,
        replace: true,
      });
    }
    redirectToUserLandingPage(auth0.user, next, locale);
  } catch {
    redirectToUserLandingPage(auth0.user, next, _targetRoute.meta?.language);
  }
};

const redirectUnAuthenticatedToLogin = (auth: VueAuth, to: Route, locale: string) =>
  new Promise<void>((resolve, reject) => {
    if (auth.isAuthenticated) {
      return resolve();
    }
    auth.loginWithRedirect({
      appState: { targetPath: to.fullPath },
      authorizationParams: {
        ui_locales: locale,
      },
    });
    reject();
  });

const getOverviewRedirectionHref = async (
  storage: StorageHelper,
  targetLanguage: string,
  user: User,
): Promise<string> => {
  let redirectHref: string = '';
  let userType: UserType | null;

  const { isTenantPlusSubscriber } = await authenticateTenantPlusUser();
  if (isTenantPlusSubscriber) {
    userType = UserType.SEEKER;
  } else {
    const isLister = user.propertiesCount > 0;
    userType = isLister ? UserType.LISTER : storage.getLocalStorageItem(Key.USER_TYPE);
  }

  if (userType != null && Object.values(UserType).includes(userType)) {
    switch (userType) {
      case UserType.LISTER:
        redirectHref = isPlatformIs24()
          ? getIS24OverviewListerUrlHref(targetLanguage)
          : getHGOverviewListerUrlHref(targetLanguage);
        break;
      case UserType.SEEKER:
        redirectHref = isPlatformIs24()
          ? getIS24OverviewSeekerUrlHref(targetLanguage)
          : getHGOverviewSeekerUrlHref(targetLanguage);
        break;
    }

    storage.setLocalStorageItem(Key.USER_TYPE, userType);
  }

  return redirectHref;
};

export {
  redirectToLogin,
  redirectToUserLandingPage,
  handleLoginCallback,
  redirectUnAuthenticatedToLogin,
  getOverviewRedirectionHref,
};
